import { FC, useEffect, useState } from "react";
import { useStyles } from "./StatisticsPage.styles";
import { useGlobalStyles } from "../../../utils/theme";
import { useSnackbar } from "notistack";

import { joinClassNames, useForm } from "../../../utils";
import { initialInputData } from "./StatisticsPage.inputs";
import {
  DataHandlerComponent,
  InfoShowcase,
  PageLayout,
} from "../../../components";
import { MenuItem, Paper, Tab, TextField, Typography } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { endOfToday, format, formatISO, isDate, startOfMonth } from "date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  ALL_CLASSIC_TOURS,
  ALL_INTERACTIVE_TOURS,
  GET_STATISTICS_CLASSIC_TOUR,
  GET_STATISTICS_INTERACTIVE_TOUR,
  GET_STATISTICS_SUBJECT,
  IClassicToursData,
  IInteractiveToursData,
  IStatisticsClassicTourData,
  IStatisticsClassicTourVars,
  IStatisticsInteractiveTourData,
  IStatisticsInteractiveTourVars,
  IStatisticsSubjectData,
  IStatisticsSubjectVars,
} from "../../../apollo/queries";
import enGb from "date-fns/locale/en-GB";
import { TabContext, TabList, TabPanel } from "@mui/lab";

export const StatisticsPage: FC = () => {
  const { classes } = useStyles();
  const { cx, classes: globalClasses } = useGlobalStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [activeTab, setActiveTab] = useState("1");

  const [startDateSubjectValue, setStartDateSubjectValue] =
    useState<Date | null>(new Date("2024-1-22 00:00:00"));
  const [endDateSubjectValue, setEndDateSubjectValue] = useState<Date | null>(
    endOfToday()
  );

  const [startDateInteractiveValue, setStartDateInteractiveValue] =
    useState<Date | null>(new Date("2024-1-22 00:00:00"));
  const [endDateInteractiveValue, setEndDateInteractiveValue] =
    useState<Date | null>(endOfToday());

  const [startDateClassicValue, setStartDateClassicValue] =
    useState<Date | null>(new Date("2024-1-22 00:00:00"));
  const [endDateClassicValue, setEndDateClassicValue] = useState<Date | null>(
    endOfToday()
  );

  const { inputFields, setInputField } =
    useForm<keyof typeof initialInputData>(initialInputData);

  const handleTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setActiveTab(newTab);
  };

  const { data, loading, error } = useQuery<
    IStatisticsSubjectData,
    IStatisticsSubjectVars
  >(GET_STATISTICS_SUBJECT, {
    variables: {
      endDate:
        inputFields.endDateSubject.inputProps.value || endDateSubjectValue,
      startDate:
        inputFields.startDateSubject.inputProps.value || startDateSubjectValue,
    },
    skip:
      (!inputFields.endDateSubject.inputProps.value && !endDateSubjectValue) ||
      (!inputFields.startDateSubject.inputProps.value &&
        !startDateSubjectValue),
    onCompleted: () => {
      if (startDateSubjectValue && endDateSubjectValue) {
        enqueueSnackbar(
          `Fetched statistics for: ${format(
            startDateSubjectValue,
            "dd.MM.yyyy."
          )} - ${format(endDateSubjectValue, "dd.MM.yyyy.")}`,
          {
            variant: "success",
          }
        );
      }
    },
  });

  const [
    queryAllInteractiveTours,
    {
      loading: loadingInteractiveTours,
      data: dataInteractiveTours,
      error: errorInteractiveTours,
    },
  ] = useLazyQuery<IInteractiveToursData>(ALL_INTERACTIVE_TOURS);

  const [
    queryAllClassicTours,
    {
      loading: loadingClassicTours,
      data: dataClassicTours,
      error: errorClassicTours,
    },
  ] = useLazyQuery<IClassicToursData>(ALL_CLASSIC_TOURS);

  const handleChangeSubjectStart = (value: Date | null) => {
    setStartDateSubjectValue(value);
    try {
      if (value && isDate(value)) {
        const formatedTime = formatISO(value);
        if (formatedTime) {
          setInputField("startDateSubject", formatedTime);
        } else {
          setInputField("startDateSubject", "");
        }
      } else setInputField("startDateSubject", "");
    } catch (err) {
      // console.log(err);
    }
  };

  const handleChangeSubjectEnd = (value: Date | null) => {
    setEndDateSubjectValue(value);
    try {
      if (value && isDate(value)) {
        const formatedTime = formatISO(value);
        if (formatedTime) {
          setInputField("endDateSubject", formatedTime);
        } else {
          setInputField("endDateSubject", "");
        }
      } else setInputField("endDateSubject", "");
    } catch (err) {
      // console.log(err);
    }
  };

  const {
    data: dataInteractive,
    loading: loadingInteractive,
    error: errorInteractive,
  } = useQuery<IStatisticsInteractiveTourData, IStatisticsInteractiveTourVars>(
    GET_STATISTICS_INTERACTIVE_TOUR,
    {
      variables: {
        endDate:
          inputFields.endDateInteractive.inputProps.value ||
          endDateInteractiveValue,
        startDate:
          inputFields.startDateInteractive.inputProps.value ||
          startDateInteractiveValue,
        interactiveTourId: +inputFields.tourIdInteractive.inputProps.value,
      },
      skip:
        (!inputFields.endDateInteractive.inputProps.value &&
          !endDateInteractiveValue) ||
        (!inputFields.startDateInteractive.inputProps.value &&
          !startDateInteractiveValue) ||
        !+inputFields.tourIdInteractive.inputProps.value,

      onCompleted: () => {
        if (startDateInteractiveValue && endDateInteractiveValue) {
          enqueueSnackbar(
            `Fetched statistics for: ${format(
              startDateInteractiveValue,
              "dd.MM.yyyy."
            )} - ${format(endDateInteractiveValue, "dd.MM.yyyy.")}`,
            {
              variant: "success",
            }
          );
        }
      },
    }
  );

  const handleChangeInteractiveStart = (value: Date | null) => {
    setStartDateInteractiveValue(value);
    try {
      if (value && isDate(value)) {
        const formatedTime = formatISO(value);
        if (formatedTime) {
          setInputField("startDateInteractive", formatedTime);
        } else {
          setInputField("startDateInteractive", "");
        }
      } else setInputField("startDateInteractive", "");
    } catch (err) {
      // console.log(err);
    }
  };

  const handleChangeInteractiveEnd = (value: Date | null) => {
    setEndDateInteractiveValue(value);
    try {
      if (value && isDate(value)) {
        const formatedTime = formatISO(value);
        if (formatedTime) {
          setInputField("endDateInteractive", formatedTime);
        } else {
          setInputField("endDateInteractive", "");
        }
      } else setInputField("endDateInteractive", "");
    } catch (err) {
      // console.log(err);
    }
  };

  const {
    data: dataClassic,
    loading: loadingClassic,
    error: errorClassic,
  } = useQuery<IStatisticsClassicTourData, IStatisticsClassicTourVars>(
    GET_STATISTICS_CLASSIC_TOUR,
    {
      variables: {
        endDate:
          inputFields.endDateClassic.inputProps.value ||
          endDateInteractiveValue,
        startDate:
          inputFields.startDateClassic.inputProps.value ||
          startDateInteractiveValue,
        classicTourId: +inputFields.tourIdClassic.inputProps.value,
      },
      skip:
        (!inputFields.endDateClassic.inputProps.value &&
          !endDateClassicValue) ||
        (!inputFields.startDateClassic.inputProps.value &&
          !startDateClassicValue) ||
        !+inputFields.tourIdClassic.inputProps.value,

      onCompleted: () => {
        if (startDateClassicValue && endDateClassicValue) {
          enqueueSnackbar(
            `Fetched statistics for: ${format(
              startDateClassicValue,
              "dd.MM.yyyy."
            )} - ${format(endDateClassicValue, "dd.MM.yyyy.")}`,
            {
              variant: "success",
            }
          );
        }
      },
    }
  );

  const handleChangeClassicStart = (value: Date | null) => {
    setStartDateClassicValue(value);
    try {
      if (value && isDate(value)) {
        const formatedTime = formatISO(value);
        if (formatedTime) {
          setInputField("startDateClassic", formatedTime);
        } else {
          setInputField("startDateClassic", "");
        }
      } else setInputField("startDateClassic", "");
    } catch (err) {
      // console.log(err);
    }
  };

  const handleChangeClassicEnd = (value: Date | null) => {
    setEndDateClassicValue(value);
    try {
      if (value && isDate(value)) {
        const formatedTime = formatISO(value);
        if (formatedTime) {
          setInputField("endDateClassic", formatedTime);
        } else {
          setInputField("endDateClassic", "");
        }
      } else setInputField("endDateClassic", "");
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    if (activeTab === "2") {
      queryAllInteractiveTours();
      return;
    }
    if (activeTab === "3") {
      queryAllClassicTours();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <PageLayout displayFlex>
      <TabContext value={activeTab}>
        <TabList onChange={handleTabChange} aria-label="lab API tabs example">
          <Tab label="Subject" value="1" />
          <Tab label="Interactive tours" value="2" />
          <Tab label="Audio tours" value="3" />
        </TabList>
        <Paper className={globalClasses.paperRoot}>
          <div
            className={cx(
              globalClasses.paperTitle,
              globalClasses.justifySpaceBetween
            )}
          >
            <Typography variant="h6">Statistics</Typography>
          </div>
          <TabPanel value="1" className={globalClasses.tabPanel}>
            <div
              className={joinClassNames([
                globalClasses.paperContainer,
                classes.container,
              ])}
            >
              <div className={globalClasses.justifySpaceBetween}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={enGb}
                >
                  <DatePicker
                    label={inputFields.startDateSubject.inputProps.label}
                    value={startDateSubjectValue}
                    onChange={handleChangeSubjectStart}
                    disableFuture
                    minDate={new Date("2024-1-22 00:00:00")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={inputFields.startDateSubject.inputProps.error}
                        variant="outlined"
                        margin="normal"
                        required
                      />
                    )}
                  />
                  <DatePicker
                    label={inputFields.endDateSubject.inputProps.label}
                    value={endDateSubjectValue}
                    disableFuture
                    minDate={new Date("2024-1-22 00:00:00")}
                    onChange={handleChangeSubjectEnd}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={inputFields.endDateSubject.inputProps.error}
                        variant="outlined"
                        margin="normal"
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div>
                <DataHandlerComponent
                  hasData={
                    data?.getStatisticsSubject !== undefined ||
                    data?.getStatisticsSubject !== null
                  }
                  loading={loading}
                  error={Boolean(error)}
                >
                  <h1>
                    Total visits:{" "}
                    <span className={classes.price}>
                      {data?.getStatisticsSubject ?? "0"}
                    </span>
                  </h1>
                </DataHandlerComponent>

                <InfoShowcase title="Data is anonymous, it does not show unique visits." />
              </div>
            </div>
          </TabPanel>

          <TabPanel value="2" className={globalClasses.tabPanel}>
            <div
              className={joinClassNames([
                globalClasses.paperContainer,
                classes.container,
              ])}
            >
              <div className={globalClasses.justifySpaceBetween}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={enGb}
                >
                  <DatePicker
                    label={inputFields.startDateInteractive.inputProps.label}
                    value={startDateInteractiveValue}
                    onChange={handleChangeInteractiveStart}
                    disableFuture
                    minDate={new Date("2024-1-22 00:00:00")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          inputFields.startDateInteractive.inputProps.error
                        }
                        variant="outlined"
                        margin="normal"
                        required
                      />
                    )}
                  />
                  <DatePicker
                    label={inputFields.endDateInteractive.inputProps.label}
                    value={endDateInteractiveValue}
                    disableFuture
                    minDate={new Date("2024-1-22 00:00:00")}
                    onChange={handleChangeInteractiveEnd}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={inputFields.endDateInteractive.inputProps.error}
                        variant="outlined"
                        margin="normal"
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <TextField
                {...inputFields.tourIdInteractive.inputProps}
                margin="normal"
                select
              >
                {dataInteractiveTours?.allInteractiveTours?.length ? (
                  dataInteractiveTours.allInteractiveTours.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.locale.title}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>Loading...</MenuItem>
                )}
              </TextField>
              <div>
                <DataHandlerComponent
                  hasData={
                    dataInteractive?.getStatisticsInteractiveTour !==
                      undefined ||
                    dataInteractive?.getStatisticsInteractiveTour !== null
                  }
                  loading={loadingInteractive || loadingInteractiveTours}
                  error={Boolean(errorInteractive || errorInteractiveTours)}
                >
                  <h1>
                    Total visits:{" "}
                    <span className={classes.price}>
                      {dataInteractive?.getStatisticsInteractiveTour ?? "0"}
                    </span>
                  </h1>
                </DataHandlerComponent>

                <InfoShowcase title="Data is anonymous, it does not show unique visits." />
              </div>
            </div>
          </TabPanel>

          <TabPanel value="3" className={globalClasses.tabPanel}>
            <div
              className={joinClassNames([
                globalClasses.paperContainer,
                classes.container,
              ])}
            >
              <div className={globalClasses.justifySpaceBetween}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={enGb}
                >
                  <DatePicker
                    label={inputFields.startDateClassic.inputProps.label}
                    value={startDateClassicValue}
                    onChange={handleChangeClassicStart}
                    disableFuture
                    minDate={new Date("2024-1-22 00:00:00")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={inputFields.startDateClassic.inputProps.error}
                        variant="outlined"
                        margin="normal"
                        required
                      />
                    )}
                  />
                  <DatePicker
                    label={inputFields.endDateClassic.inputProps.label}
                    value={endDateClassicValue}
                    disableFuture
                    minDate={new Date("2024-1-22 00:00:00")}
                    onChange={handleChangeClassicEnd}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={inputFields.endDateClassic.inputProps.error}
                        variant="outlined"
                        margin="normal"
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <TextField
                {...inputFields.tourIdClassic.inputProps}
                margin="normal"
                select
              >
                {dataClassicTours?.allClassicTours?.length ? (
                  dataClassicTours.allClassicTours.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.locale?.title}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>Loading...</MenuItem>
                )}
              </TextField>
              <div>
                <DataHandlerComponent
                  hasData={
                    dataClassic?.getStatisticsClassicTour !== undefined ||
                    dataClassic?.getStatisticsClassicTour !== null
                  }
                  loading={loadingClassic || loadingClassicTours}
                  error={Boolean(errorClassic || errorClassicTours)}
                >
                  <h1>
                    Total visits:{" "}
                    <span className={classes.price}>
                      {dataClassic?.getStatisticsClassicTour ?? "0"}
                    </span>
                  </h1>
                </DataHandlerComponent>

                <InfoShowcase title="Data is anonymous, it does not show unique visits." />
              </div>
            </div>
          </TabPanel>
        </Paper>
      </TabContext>
    </PageLayout>
  );
};
