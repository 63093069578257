import { createTypedDynamicFormInputs } from "../../../utils/formUtils";

export const initialInputData = createTypedDynamicFormInputs({
  title: {
    inputProps: {
      label: "Station Title",
      name: "stationTitle",
      placeholder: "A name of your Station",
      required: true,
    },
    localise: true,
    mainHelperText:
      "Title should be short and grab visitor attention enough to engage visitors into visiting this Station. Do not include Station numbering. It is added automatically.",
  },
  description: {
    inputProps: {
      label: "Station description",
      placeholder: "A short description of this station",
      required: false,
      name: "stationDescription",
    },
    localise: true,
    mainHelperText:
      "This short description should give visitors enough information to know what that particular Station is all about.",
  },
  audioGuideText: {
    inputProps: {
      label: "Audio guide text",
      placeholder: "A full text version of the audio guide.",
      required: true,
      name: "audioGuideText",
    },
    localise: true,
    mainHelperText:
      "In this field you should insert the text that is spoken in the audio guide.",
  },
  gallery: {
    inputProps: {
      label: "Gallery",
      required: true,
      name: "gallery",
    },
    rule: "media",
    mainLocaleRequiredOnly: true,
    localise: true,
  },
  audioGuide: {
    inputProps: {
      label: "Audio guide",
      name: "audioGuide",
      required: true,
    },
    rule: "media",
    localise: true,
  },
  textCode: {
    mainHelperText: "The code is case insensitive",
    inputProps: {
      label: "Text code",
      name: "textCode",
      placeholder: "a-10",
    },
  },
});
