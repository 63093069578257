export const ITEMS_PER_PAGE = 25;
export const MAIN_ISO_LANGUAGE_CODE = "en-GB";

//INPUT

export const INPUT_CLASSIC_TOUR_TITLE_MAX_LENGTH = 80;
export const INPUT_CLASSIC_TOUR_DESCRIPTION_MAX_LENGTH = 10000;

export const INPUT_STATION_TITLE_MAX_LENGTH = 80;
export const INPUT_STATION_DESCRIPTION_MAX_LENGTH = 500;
export const INPUT_STATION_AUDIO_GUIDE_TEXT_MAX_LENGTH = 10000;

export const INPUT_SUBJECT_ABOUT_MAX_LENGTH = 5000;
export const INPUT_SUBJECT_EXTRA_INFO_MAX_LENGTH = 1000;
export const INPUT_SUBJECT_OPENING_HOURS_INFO_MAX_LENGTH = 1000;

export const INPUT_SUBJECT_NAME_MAX_LENGTH = 80;
export const INPUT_STATION_CODE_MAX_LENGTH = 10;

export const INPUT_INTERACTIVE_TOUR_TITLE_MAX_LENGTH = 80;
export const INPUT_INTERACTIVE_TOUR_DESCRIPTION_MAX_LENGTH = 5000;
export const INPUT_INTERACTIVE_TOUR_INTRODUCTION_MAX_LENGTH = 5000;
export const INPUT_INTERACTIVE_TOUR_CONCLUSION_MAX_LENGTH = 5000;
export const INPUT_INTERACTIVE_TOUR_STARTING_POINT_MAX_LENGTH = 120;
export const INPUT_INTERACTIVE_TOUR_IMPORTANT_INFO_MAX_LENGTH = 1500;

export const INPUT_CHECKPOINT_TITLE_MAX_LENGTH = 80;
export const INPUT_CHECKPOINT_DIRECTIONS_MAX_LENGTH = 350;
export const INPUT_CHECKPOINT_DIRECTIONS_HINT_MAX_LENGTH = 350;
export const INPUT_CHECKPOINT_OPENING_MAX_LENGTH = 5000;
export const INPUT_CHECKPOINT_CHALLENGE_MAX_LENGTH = 500;
export const INPUT_CHECKPOINT_CHALLENGE_HINT_MAX_LENGTH = 350;
export const INPUT_CHECKPOINT_SOLUTION_MAX_LENGTH = 250;
export const INPUT_CHECKPOINT_CLOSING_MAX_LENGTH = 5000;
export const INPUT_CHECKPOINT_FACTS_MAX_LENGTH = 5000;

//ERRORS

export const ERROR_MAIN_LOCALE_NOT_FOUND = "Main locale not found!";
export const MSG_UNSAVED_CHANGES =
  "You have some unsaved changes. Please update them or revert those changes and try again.";
export const MSG_GENERIC_ERROR =
  "Error while performing operation. Refresh the page and try again or contact support.";

//FILE UPLOAD
//600kb
export const MAX_IMAGE_UPLOAD_SIZE = 600000;
//50mb
export const MAX_AUDIO_UPLOAD_SIZE = 50000000;

export type TValidTypes = (
  | "image/jpeg"
  | "image/jpg"
  | "image/png"
  | "audio/mpeg"
)[];

export const ALLOWED_IMAGE_TYPES: TValidTypes = [
  "image/jpeg",
  "image/png",
  "image/jpg",
];
export const ALLOWED_AUDIO_TYPES: TValidTypes = ["audio/mpeg"];

export const ALLOWED_MEDIA_TYPES: TValidTypes = [
  ...ALLOWED_IMAGE_TYPES,
  ...ALLOWED_AUDIO_TYPES,
];

export const UNKNOWN_ERROR_STRING =
  "Unknown error occurred! Please try again or contact support!";

//ENUMS
export enum ERouterPaths {
  CLASSIC_TOURS = "classic-tours",
  INTERACTIVE_TOURS = "interactive-tours",
  STATIONS = "stations",
  MEDIA = "media",
  ABOUT_US = "about-us",
  ADMINISTRATORS = "administrators",
  REPORTS = "reports",
  LOGIN = "login",
  FORGOT_PASSWORD = "forgot-password",
  RESET_PASSWORD = "reset-password",
  VALIDATE_PERSON = "validate-person",

  STATISTICS = "statistics",

  RECEPTION = "reception",
  RECEIPTS = "receipts",
  PRODUCTS = "products",
  SALES = "sales",

  //FAQ = "faq", //TODO: Later: Move site to cms, not external docs
  FAQ = "https://docs.google.com/document/d/1krIp98xZrsN2wXxkY2w3b7I65CUf4wwCtUC2DcJ4_no",
  GUIDELINES = "guidelines",

  JOIN = "join",
}

export enum ESource {
  GOOGLE_IN_APP_PUCHASE = "GOOGLE_IN_APP_PUCHASE",
  APPLE_IN_APP_PUCHASE = "APPLE_IN_APP_PUCHASE",
  RECEPTION = "RECEPTION",
}
