import {
  Button,
  InputAdornment,
  Paper,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
// import { useStyles } from "./ClassicClassicTourPage.styles";
import { TabList, TabContext, TabPanel } from "@mui/lab";
import {
  DataHandlerComponent,
  FlagList,
  LoadingBackdrop,
  PageLayout,
  ReadyToPublish,
  SortableTreeList,
} from "../../../components";
import { useGlobalStyles } from "../../../utils/theme";
import {
  ERouterPaths,
  IDialogContext,
  IMediaSelectDialogContext,
  INPUT_CLASSIC_TOUR_DESCRIPTION_MAX_LENGTH,
  INPUT_CLASSIC_TOUR_TITLE_MAX_LENGTH,
  MAIN_ISO_LANGUAGE_CODE,
  MSG_GENERIC_ERROR,
  MSG_UNSAVED_CHANGES,
  TreeItem,
  TreeItems,
  useForm,
} from "../../../utils";
import { initialInputData } from "./ClassicTourPage.inputs";
import {
  Add as AddIcon,
  ChevronRightOutlined as ChevronRightOutlinedIcon,
} from "@mui/icons-material";
import { ChevronLeftOutlined as ChevronLeftOutlinedIcon } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import {
  Reference,
  StoreObject,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import {
  CREATE_CLASSIC_TOUR,
  CREATE_CLASSIC_TOUR_LOCALE,
  DELETE_CLASSIC_TOUR_LOCALE,
  DELETE_STATION,
  ICreateClassicTourData,
  ICreateClassicTourLocaleData,
  ICreateClassicTourLocaleVars,
  ICreateClassicTourVars,
  IDeleteClassicTourLocaleData,
  IDeleteClassicTourLocaleVars,
  IDeleteStationData,
  IDeleteStationVars,
  IUpdateClassicTourLocaleData,
  IUpdateClassicTourLocaleVars,
  IUpdateClassicTourOtherData,
  IUpdateClassicTourOtherVars,
  IUpdateClassicTourPublishedData,
  IUpdateClassicTourPublishedVars,
  IUpdateStationOrderData,
  IUpdateStationOrderVars,
  UPDATE_CLASSIC_TOUR_LOCALE,
  UPDATE_CLASSIC_TOUR_OTHER,
  UPDATE_CLASSIC_TOUR_PUBLISHED,
  UPDATE_STATION_ORDER,
} from "../../../apollo/mutations";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  ALL_CLASSIC_TOURS,
  IStation,
  IClassicTour,
  IClassicTourDetailsData,
  IClassicTourDetailsVars,
  IClassicToursData,
  ONE_CLASSIC_TOUR_DETAILS,
} from "../../../apollo/queries";
import {
  MediaSelectButton,
  MediaSelectorDialog,
} from "../../../components/mediaSelectorContent/components";
import { DeleteDialog } from "../../../components";

export const ClassicTourPage: FC = () => {
  // const { classes } = useStyles();
  const { classes: globalClasses } = useGlobalStyles();
  const { classicTourId } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();

  const TOTAL_TABS = "3";
  const isEdit = classicTourId !== undefined;

  const [activeTab, setActiveTab] = useState("1");
  const [checkPassedPublishTest, setCheckPassedPublishTest] = useState(false);
  const [orderedStations, setOrderedStations] = useState<TreeItems>([]);
  const [mediaSelectDialogOpen, setMediaSelectDialogOpen] =
    useState<IMediaSelectDialogContext>({
      open: false,
      type: undefined,
      max: undefined,
      onChange: undefined,
      selectedMedia: [],
    });

  const [stationDialogContext, setStationDialogContext] =
    useState<IDialogContext>({
      open: false,
      type: undefined,
      id: undefined,
    });

  const handleCloseMediaSelectDialog = () => {
    setMediaSelectDialogOpen((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const handleCloseStationDialog = () => {
    setStationDialogContext((prevState) => ({
      ...prevState,
      open: false,
      callback: undefined,
    }));
  };

  const [queryOneClassicTourDetails, { loading, data, error }] = useLazyQuery<
    IClassicTourDetailsData,
    IClassicTourDetailsVars
  >(ONE_CLASSIC_TOUR_DETAILS);

  //*---------------MUTATIONS
  const {
    inputFields,
    activeLocale,
    setActiveLocale,
    currentLanguageFlags,
    setCurrentLanguageFlags,
    didValuesChange,
    handleDataToVar,
    getFormValuesFromFetchedData,
    inputFieldNames,
    validateForm,
    resetFields,
    // formError,
  } = useForm<keyof typeof initialInputData>(initialInputData);

  const [
    createClassicTourMutation,
    { loading: loadingCreateClassicTourMutation },
  ] = useMutation<ICreateClassicTourData, ICreateClassicTourVars>(
    CREATE_CLASSIC_TOUR,
    {
      onCompleted: (data) => {
        enqueueSnackbar(
          `New Audio tour created: ${data.createClassicTour.locale.title}`,
          {
            variant: "success",
          }
        );
        navigate(`/${ERouterPaths.CLASSIC_TOURS}`);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      //TODO: Check if no classicTours
      update: (cache, { data }) => {
        const existingListData: IClassicToursData | null = cache.readQuery({
          query: ALL_CLASSIC_TOURS,
        });
        if (data?.createClassicTour) {
          const newClassicTourData: IClassicTour = {
            ...data.createClassicTour,
          };
          cache.writeQuery({
            query: ALL_CLASSIC_TOURS,
            data: {
              allClassicTours: existingListData?.allClassicTours
                ? [newClassicTourData, ...existingListData.allClassicTours]
                : [newClassicTourData],
            },
          });
        }
      },
    }
  );

  const [
    updateClassicTourLocaleMutation,
    { loading: loadingUpdateClassicTourLocaleMutation },
  ] = useMutation<IUpdateClassicTourLocaleData, IUpdateClassicTourLocaleVars>(
    UPDATE_CLASSIC_TOUR_LOCALE,
    {
      onCompleted: (data) => {
        enqueueSnackbar(
          `Locale updated: ${
            data.updateClassicTourLocale.title || "Nameless Audio tour"
          }`,
          {
            variant: "success",
          }
        );
        resetFields(["description", "gallery", "title"], true, false);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      //TODO: Update!
      //? Not needed?
      // update: (cache, { data }) => {
      //   const existingListData: IClassicToursData | null = cache.readQuery({
      //     query: ALL_CLASSIC_TOURS,
      //   });
      //   if (data?.createClassicTour) {
      //     const newClassicTourData: IClassicTour = {
      //       ...data.createClassicTour,
      //     };
      //     cache.writeQuery({
      //       query: ALL_CLASSIC_TOURS,
      //       data: {
      //         allClassicTours: existingListData?.allClassicTours
      //           ? [newClassicTourData, ...existingListData.allClassicTours]
      //           : [newClassicTourData],
      //       },
      //     });
      //   }
      // },
    }
  );

  const [
    deleteClassicTourLocaleMutation,
    { loading: loadingDeleteClassicTourLocaleMutation },
  ] = useMutation<IDeleteClassicTourLocaleData, IDeleteClassicTourLocaleVars>(
    DELETE_CLASSIC_TOUR_LOCALE,
    {
      onCompleted: (data) => {
        enqueueSnackbar("Audio tour flag deleted!", {
          variant: "success",
        });
        resetFields(["description", "gallery", "title"]);
        setActiveLocale(MAIN_ISO_LANGUAGE_CODE);
        const newCurrentFlags = currentLanguageFlags.filter(
          (x) => x.id !== data.deleteClassicTourLocale.localeId
        );
        setCurrentLanguageFlags(newCurrentFlags);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      update(cache, { data: dataCreate }) {
        if (data?.oneClassicTourDetails) {
          cache.modify({
            id: cache.identify(
              data.oneClassicTourDetails as unknown as StoreObject
            ),
            fields: {
              allLocales(existingData: Array<Reference>, { readField }) {
                if (data) {
                  if (existingData && dataCreate) {
                    return existingData.filter(
                      (taskRef) =>
                        dataCreate.deleteClassicTourLocale.id !==
                        readField("id", taskRef)
                    );
                  }
                }
              },
            },
          });
        }
      },
    }
  );

  const handleDeleteClassicTourLocale = () => {
    const localeId = data?.oneClassicTourDetails?.allLocales?.find(
      (x) => x.languageFlag.isoLanguageCode === activeLocale
    )?.id;
    if (localeId) {
      deleteClassicTourLocaleMutation({
        variables: {
          id: +localeId,
        },
      });
    } else {
      const newCurrentFlags = currentLanguageFlags.filter(
        (x) => x.isoLanguageCode !== activeLocale
      );
      resetFields(["description", "gallery", "title"]);
      setCurrentLanguageFlags(newCurrentFlags);
      setActiveLocale(MAIN_ISO_LANGUAGE_CODE);
      enqueueSnackbar("Locale has been removed!", { variant: "success" });
    }
  };

  const [
    updateClassicTourPublishedMutation,
    { loading: loadingUpdateClassicTourPublishedMutation },
  ] = useMutation<
    IUpdateClassicTourPublishedData,
    IUpdateClassicTourPublishedVars
  >(UPDATE_CLASSIC_TOUR_PUBLISHED, {
    onCompleted: (data) => {
      if (!data.updateClassicTourPublished) {
        enqueueSnackbar(`Audio tour is ready to be published!`, {
          variant: "info",
        });
        setCheckPassedPublishTest(true);
      } else {
        enqueueSnackbar(
          `Audio tour is now ${
            data.updateClassicTourPublished.published
              ? "published"
              : "unpublished"
          }!`,
          {
            variant: "success",
          }
        );
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
    //TODO: Update!
    //? Not needed?
    // update: (cache, { data }) => {
    //   const existingListData: IClassicToursData | null = cache.readQuery({
    //     query: ALL_CLASSIC_TOURS,
    //   });
    //   if (data?.createClassicTour) {
    //     const newClassicTourData: IClassicTour = {
    //       ...data.createClassicTour,
    //     };
    //     cache.writeQuery({
    //       query: ALL_CLASSIC_TOURS,
    //       data: {
    //         allClassicTours: existingListData?.allClassicTours
    //           ? [newClassicTourData, ...existingListData.allClassicTours]
    //           : [newClassicTourData],
    //       },
    //     });
    //   }
    // },
  });

  const [
    updateClassicTourOtherMutation,
    { loading: loadingUpdateClassicTourOtherMutation },
  ] = useMutation<IUpdateClassicTourOtherData, IUpdateClassicTourOtherVars>(
    UPDATE_CLASSIC_TOUR_OTHER,
    {
      onCompleted: (data) => {
        enqueueSnackbar("Other info updated", {
          variant: "success",
        });
        resetFields(["duration"], true);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      //TODO: Update!
      //? Not needed?
      // update: (cache, { data }) => {
      //   const existingListData: IClassicToursData | null = cache.readQuery({
      //     query: ALL_CLASSIC_TOURS,
      //   });
      //   if (data?.createClassicTour) {
      //     const newClassicTourData: IClassicTour = {
      //       ...data.createClassicTour,
      //     };
      //     cache.writeQuery({
      //       query: ALL_CLASSIC_TOURS,
      //       data: {
      //         allClassicTours: existingListData?.allClassicTours
      //           ? [newClassicTourData, ...existingListData.allClassicTours]
      //           : [newClassicTourData],
      //       },
      //     });
      //   }
      // },
    }
  );

  // const [
  //   updateClassicTourStationMutation,
  //   { loading: loadingUpdateClassicTourStation },
  // ] = useMutation<IUpdateClassicTourStationData, IUpdateClassicTourStationVars>(
  //   UPDATE_CLASSIC_TOUR_STATION,
  //   {
  //     onCompleted: (data) => {
  //       // console.log({ data });
  //       enqueueSnackbar("Station list updated!", {
  //         variant: "success",
  //       });
  //     },
  //     onError: (error) => {
  //       enqueueSnackbar(error.message, {
  //         variant: "error",
  //       });
  //     },

  //     update: (cache, { data }) => {
  //       if (data?.updateClassicTourStation) {
  //         // console.log(cache);
  //         cache.modify({
  //           id: cache.identify(
  //             data.updateClassicTourStation as unknown as StoreObject
  //           ),
  //           fields: {
  //             allStations(existingData: Array<Reference>, { readField }) {
  //               if (data) {
  //                 if (
  //                   existingData &&
  //                   data.updateClassicTourStation &&
  //                   data.updateClassicTourStation.allStations.length
  //                 ) {
  //                   // console.log({ existingData });
  //                   return [
  //                     readField(
  //                       "allStations",
  //                       data.updateClassicTourStation as unknown as StoreObject
  //                     ),
  //                   ];
  //                 } else {
  //                   return [];
  //                 }
  //               }
  //             },
  //           },
  //         });
  //       }
  //     },
  //   }
  // );

  const [
    createClassicTourLocaleMutation,
    { loading: loadingCreateClassicTourLocaleMutation },
  ] = useMutation<ICreateClassicTourLocaleData, ICreateClassicTourLocaleVars>(
    CREATE_CLASSIC_TOUR_LOCALE,
    {
      onCompleted: (data) => {
        enqueueSnackbar(
          `Locale create: ${data.createClassicTourLocale.title}`,
          {
            variant: "success",
          }
        );
        resetFields(["description", "gallery", "title"], true);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      update: (cache, { data: dataCreate }) => {
        // console.log(cache);
        if (data?.oneClassicTourDetails) {
          cache.modify({
            id: cache.identify(
              data.oneClassicTourDetails as unknown as StoreObject
            ),
            fields: {
              allLocales(existingData: Array<Reference>, { readField }) {
                if (data) {
                  if (existingData && dataCreate) {
                    return [
                      ...existingData,
                      {
                        // @ts-ignore
                        __ref: `${dataCreate.createClassicTourLocale.__typename}:${dataCreate.createClassicTourLocale.id}`,
                      },
                    ];
                  }
                }
              },
            },
          });
        }
      },
    }
  );
  //*-----------------------
  useEffect(() => {
    if (classicTourId) {
      queryOneClassicTourDetails({ variables: { id: +classicTourId } });
    }
  }, [classicTourId, queryOneClassicTourDetails]);

  useEffect(() => {
    if (searchParams) {
      const getQueryTab = searchParams.get("activeTab");
      if (getQueryTab) {
        setActiveTab(getQueryTab);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setActiveTab(newTab);
    setSearchParams({ activeTab: newTab });
  };

  const handleNextTab = () => {
    const nextTab = (parseInt(activeTab) + 1).toString();
    setActiveTab(nextTab);
    setSearchParams({ activeTab: nextTab });
  };

  const handlePrevTab = () => {
    const prevTab = (parseInt(activeTab) - 1).toString();
    setActiveTab(prevTab);
    setSearchParams({ activeTab: prevTab });
  };

  const handleCreateClassicTour = () => {
    if (!validateForm(inputFieldNames, false)) {
      return;
    }

    createClassicTourMutation({
      variables: {
        data: {
          duration: handleDataToVar("duration", "string", false),
          stations: orderedStations.length
            ? orderedStations.map((item) => +item.id)
            : undefined,
        },
        dataLocale: {
          title: handleDataToVar("title", "string", false),
          description: handleDataToVar("description", "string", false),
          gallery: handleDataToVar("gallery", "numberArray", false),
        },
      },
    });
  };

  useEffect(() => {
    if (!data?.oneClassicTourDetails) {
      return;
    }

    getFormValuesFromFetchedData(
      data.oneClassicTourDetails,
      [
        {
          fromDataProperty: "allLocales.title",
          toFormProperty: "title",
        },
        {
          fromDataProperty: "allLocales.description",
          toFormProperty: "description",
        },
        {
          fromDataProperty: "allLocales.gallery.id",
          toFormProperty: "gallery",
        },
        {
          fromDataProperty: "duration",
          toFormProperty: "duration",
        },
      ],
      true,
      true
    );

    if (data.oneClassicTourDetails.allStations?.length) {
      const prepareStations = (stations: IStation[]): TreeItems =>
        stations.map((station, i) => {
          const treeItem: TreeItem = {
            id: station.id,
            data: {
              published: station.published,
              title: station.locale.title || `Unnamed station - ${station.id}`,
            },
            children: [],
          };
          const subStations = station.children ?? [];

          if (subStations.length === 0) return treeItem;
          treeItem.children = prepareStations(subStations);

          return treeItem;
        });

      const preparedData = prepareStations(
        data.oneClassicTourDetails.allStations
      );

      // console.log(JSON.stringify(preparedData, null, 2));

      setOrderedStations(preparedData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.oneClassicTourDetails]);

  const handleUpdateLocale = () => {
    if (
      !validateForm(
        ["title", "description", "gallery"],
        Boolean(data?.oneClassicTourDetails.published)
      )
    ) {
      return;
    }

    const getUpdateLocaleId = isEdit
      ? data?.oneClassicTourDetails.allLocales?.find(
          (x) => x.languageFlag?.isoLanguageCode === activeLocale
        )?.id
      : undefined;

    if (getUpdateLocaleId) {
      updateClassicTourLocaleMutation({
        variables: {
          id: +getUpdateLocaleId,
          data: {
            title: handleDataToVar("title", "string"),
            description: handleDataToVar("description", "string"),
            gallery: handleDataToVar("gallery", "numberArray"),
          },
        },
      });
    } else if (classicTourId) {
      createClassicTourLocaleMutation({
        variables: {
          classicTourId: +classicTourId,
          data: {
            title: handleDataToVar("title", "string", false),
            description: handleDataToVar("description", "string", false),
            gallery: handleDataToVar("gallery", "numberArray", false),
            isoLanguageCode: activeLocale,
          },
        },
      });
    }
  };

  const handleUpdateOther = () => {
    if (
      !validateForm(
        ["duration"],
        Boolean(data?.oneClassicTourDetails.published)
      )
    ) {
      return;
    }
    if (isEdit && classicTourId) {
      updateClassicTourOtherMutation({
        variables: {
          id: +classicTourId,
          data: {
            duration: handleDataToVar("duration", "number"),
          },
        },
      });
    }
  };

  // const handleUpdateClassicTourStation = () => {
  //   if (isEdit && classicTourId) {
  //     let iteration = 0;
  //     const prepareStations = (treeItems: TreeItems, parentId?: number): any =>
  //       treeItems
  //         .map((treeItem, i) => {
  //           const station: any = {
  //             id: +treeItem.id,
  //             order: iteration,
  //             parentId: parentId,
  //           };
  //           const subStations = treeItem.children ?? [];
  //           iteration++;

  //           if (subStations.length === 0) return station;

  //           const childrenStations = prepareStations(subStations, +treeItem.id);

  //           return [station, ...childrenStations];
  //         })
  //         .flat(10);

  //     const prepData = prepareStations(orderedStations);

  //     // console.log(JSON.stringify(prepData, null, 2));

  //     updateClassicTourStationMutation({
  //       variables: {
  //         id: +classicTourId,
  //         data: {
  //           stations: prepData,
  //         },
  //       },
  //     });
  //   } else {
  //     enqueueSnackbar("ID not found!", {
  //       variant: "error",
  //     });
  //   }
  // };

  const handleUpdateClassicTourPublished = () => {
    if (data && isEdit && classicTourId) {
      if (
        didValuesChange(inputFieldNames, true) &&
        !data.oneClassicTourDetails.published
      ) {
        enqueueSnackbar(MSG_UNSAVED_CHANGES, { variant: "warning" });
        return;
      }
      if (
        validateForm(
          inputFieldNames,
          !data.oneClassicTourDetails.published,
          true
        )
      ) {
        updateClassicTourPublishedMutation({
          variables: {
            id: +classicTourId,
            publish: data?.oneClassicTourDetails.published ? false : true,
            checkOnly:
              !data?.oneClassicTourDetails.published && !checkPassedPublishTest,
          },
        });
      }
    } else {
      enqueueSnackbar("ID not found!", {
        variant: "error",
      });
    }
  };

  // const handleRemoveOrderedStation = () => {
  //   if (orderedStations.length && stationDialogContext.id) {
  //     const newOrderedStations = orderedStations.filter(
  //       (x) => x.id !== stationDialogContext.id
  //     );
  //     setOrderedStations(newOrderedStations);
  //     handleCloseStationDialog();
  //     enqueueSnackbar("Station disconnected", {
  //       variant: "info",
  //     });
  //   } else {
  //     enqueueSnackbar("Station not found", {
  //       variant: "error",
  //     });
  //   }
  // };

  const handleCreateNewStation = () => {
    if (didValuesChange()) {
      enqueueSnackbar(MSG_UNSAVED_CHANGES, { variant: "warning" });
      return;
    }
    navigate("new");
  };

  // function handleRemoveTreeItem(id: string) {
  //   setOrderedStations((items) => removeItem(items, id));
  // }

  const [deleteStationMutation, { loading: loadingDeleteStationMutation }] =
    useMutation<IDeleteStationData, IDeleteStationVars>(DELETE_STATION, {
      onCompleted: (res) => {
        enqueueSnackbar("Station deleted!", { variant: "success" });
        handleCloseStationDialog();
      },
      onError: (error) => {
        // console.log({ error });
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      //TODO: Flickers, needs fixing
      refetchQueries: [
        {
          query: ONE_CLASSIC_TOUR_DETAILS,
          variables: { id: classicTourId ? +classicTourId : 0 },
        },
      ],
      //TODO: Fix cache. depth makes it not work correctly
      // update(cache, { data }) {
      //   cache.modify({
      //     fields: {
      //       allStations(existingStations: Array<Reference>, { readField }) {
      //         if (data) {
      //           return existingStations.filter(
      //             (taskRef) =>
      //               data.deleteStation.id !== readField("id", taskRef)
      //           );
      //         }
      //       },
      //     },
      //   });
      // },
    });

  const handleRemoveOrderedStation = () => {
    if (
      !orderedStations.length ||
      !stationDialogContext.id ||
      stationDialogContext.type !== "delete"
    ) {
      enqueueSnackbar(MSG_GENERIC_ERROR, { variant: "error" });
      return;
    }
    deleteStationMutation({
      variables: { id: +stationDialogContext.id },
    });
    const newOrderedStations = orderedStations.filter(
      (x) => x.id !== stationDialogContext.id
    );
    setOrderedStations(newOrderedStations);

    //   handleCloseStationDialog();
    //   enqueueSnackbar("Checkpoint disconnected", {
    //     variant: "success",
    //   });
    //   if (checkpointDialogContext.callback) {
    //     checkpointDialogContext.callback();
    //   }
    // } else {
    //   enqueueSnackbar("Checkpoint not found", {
    //     variant: "error",
    //   });
    // }
  };

  const handleEditStation = (id: string) => {
    navigate(id);
  };

  const handleOpenDeleteStationDialog = (id: string, callback: () => void) => {
    setStationDialogContext({
      open: true,
      id: id,
      type: "delete",
      callback: callback,
    });
  };

  const [updateStationOrderMutation, { loading: loadingUpdateStationOrder }] =
    useMutation<IUpdateStationOrderData, IUpdateStationOrderVars>(
      UPDATE_STATION_ORDER,
      {
        onCompleted: (data) => {
          enqueueSnackbar("Checkpoint order updated", {
            variant: "success",
          });
        },
        //TODO: Update!
        //? Not needed?
        // update: (cache, { data }) => {
        //   const existingListData: IToursData | null = cache.readQuery({
        //     query: ALL_TOURS,
        //   });
        //   if (data?.createTour) {
        //     const newTourData: ITour = {
        //       ...data.createTour,
        //     };
        //     cache.writeQuery({
        //       query: ALL_TOURS,
        //       data: {
        //         allTours: existingListData?.allTours
        //           ? [newTourData, ...existingListData.allTours]
        //           : [newTourData],
        //       },
        //     });
        //   }
        // },
      }
    );

  const handleReorderStationMutation = (
    beforeId: string | undefined,
    toId: string,
    nextId: string | undefined,
    parentId: string | null,
    callback?: () => void
  ) => {
    updateStationOrderMutation({
      variables: {
        beforeId: beforeId ? +beforeId : undefined,
        toId: +toId,
        nextId: nextId ? +nextId : undefined,
        parentId: parentId ? +parentId : null,
      },
      onCompleted: () => {
        if (callback) {
          callback();
        }
      },
      onError: (err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      },
    });
  };

  return (
    <PageLayout displayFlex>
      <TabContext value={activeTab}>
        <TabList onChange={handleTabChange} aria-label="lab API tabs example">
          <Tab label="General" value="1" />
          <Tab label="Other" value="2" />
          <Tab label="Stations" value="3" />
        </TabList>
        <Paper className={globalClasses.paperRoot}>
          <div className={globalClasses.paperTitle}>
            <div className={globalClasses.justifySpaceBetween}>
              <Typography variant="h6">
                {isEdit ? "Edit Audio tour" : "Add a new Audio tour"}
              </Typography>
              {isEdit && data?.oneClassicTourDetails ? (
                <ReadyToPublish
                  handlePublish={handleUpdateClassicTourPublished}
                  checkPassed={checkPassedPublishTest}
                  published={data.oneClassicTourDetails.published}
                  publishedThingText="Audio tour"
                />
              ) : null}
            </div>
          </div>
          <DataHandlerComponent
            hasData={Boolean(
              !isEdit || (isEdit && data?.oneClassicTourDetails)
            )}
            error={Boolean(error)}
            loading={loading}
          >
            <div className={globalClasses.paperContainer}>
              <TabPanel value="1" className={globalClasses.tabPanel}>
                <FlagList
                  deleteLocaleFlagMutation={handleDeleteClassicTourLocale}
                  canSelectFlags={isEdit}
                  activeLocale={activeLocale}
                  setActiveLocale={setActiveLocale}
                  currentLanguageFlags={currentLanguageFlags}
                  setCurrentLanguageFlags={setCurrentLanguageFlags}
                  type="Audio tour"
                  tooltip='New translations for Audio tours and Stations will only be visible to users if you have already added them in the "About us" section.'
                />
                {!isEdit ? (
                  <Typography color="textSecondary">
                    Add the first details about the Audio tour you want to
                    create.
                  </Typography>
                ) : null}

                <TextField
                  margin="normal"
                  {...inputFields.title.inputProps}
                  autoFocus
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_CLASSIC_TOUR_TITLE_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.title.inputProps.value.length}/${INPUT_CLASSIC_TOUR_TITLE_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  margin="normal"
                  {...inputFields.description.inputProps}
                  multiline
                  minRows={6}
                  maxRows={10}
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_CLASSIC_TOUR_DESCRIPTION_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.description.inputProps.value.length}/${INPUT_CLASSIC_TOUR_DESCRIPTION_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />

                <MediaSelectButton
                  setMediaSelectDialogOpen={setMediaSelectDialogOpen}
                  max={8}
                  pickType="image"
                  {...inputFields.gallery.inputProps}
                />
              </TabPanel>
              <TabPanel className={globalClasses.tabPanel} value="2">
                {!isEdit ? (
                  <Typography color="textSecondary">
                    Add additional content to your Audio tour.
                  </Typography>
                ) : null}

                <TextField
                  margin="normal"
                  {...inputFields.duration.inputProps}
                  autoFocus
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">minutes</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </TabPanel>
              <TabPanel className={globalClasses.tabPanel} value="3">
                <div className={globalClasses.justifySpaceBetween}>
                  {!isEdit ? (
                    <>
                      <Typography color="textSecondary">
                        You can create Stations here once you create your Audio
                        tour.
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography color="textSecondary">
                        Create, edit, delete or reorder Stations here.
                      </Typography>

                      <Button
                        startIcon={<AddIcon />}
                        onClick={handleCreateNewStation}
                        variant="outlined"
                      >
                        Add new Station
                      </Button>
                    </>
                  )}
                </div>
                <SortableTreeList
                  loading={loading}
                  handleDelete={handleOpenDeleteStationDialog}
                  handleEdit={handleEditStation}
                  collapsible
                  indicator
                  orderedList={orderedStations}
                  setOrderedList={setOrderedStations}
                  onReorder={handleReorderStationMutation}
                  maxDepth={2}
                />
              </TabPanel>
            </div>
            <div className={globalClasses.paperButtons}>
              <div className={globalClasses.paperButtonsLeft}>
                {activeTab !== "1" ? (
                  <Button
                    onClick={handlePrevTab}
                    variant="outlined"
                    color="inherit"
                    startIcon={<ChevronLeftOutlinedIcon />}
                  >
                    Previous
                  </Button>
                ) : null}
              </div>
              <div className={globalClasses.paperButtonsRight}>
                {activeTab !== TOTAL_TABS ? (
                  <Button
                    onClick={handleNextTab}
                    variant="outlined"
                    endIcon={<ChevronRightOutlinedIcon />}
                  >
                    Next
                  </Button>
                ) : activeTab === TOTAL_TABS && !isEdit ? (
                  <Button onClick={handleCreateClassicTour} variant="contained">
                    Finish creating Audio tour
                  </Button>
                ) : null}

                {activeTab === "1" && isEdit ? (
                  <Button
                    onClick={handleUpdateLocale}
                    variant="contained"
                    disabled={
                      !didValuesChange(["title", "description", "gallery"]) &&
                      !!data?.oneClassicTourDetails.allLocales?.find(
                        (x) => x.languageFlag?.isoLanguageCode === activeLocale
                      )?.id
                    }
                  >
                    {!data?.oneClassicTourDetails.allLocales?.find(
                      (x) => x.languageFlag?.isoLanguageCode === activeLocale
                    )?.id
                      ? "create "
                      : "update "}
                    | {activeLocale} |
                  </Button>
                ) : null}
                {activeTab === "2" && isEdit ? (
                  <Button
                    onClick={handleUpdateOther}
                    variant="contained"
                    disabled={!didValuesChange(["duration"])}
                  >
                    Update
                  </Button>
                ) : null}
              </div>
            </div>
          </DataHandlerComponent>
        </Paper>
      </TabContext>
      <LoadingBackdrop
        loading={
          loadingCreateClassicTourMutation ||
          loadingUpdateClassicTourLocaleMutation ||
          loadingCreateClassicTourLocaleMutation ||
          loadingUpdateClassicTourOtherMutation ||
          // loadingUpdateClassicTourStation ||
          loadingUpdateClassicTourPublishedMutation ||
          loadingDeleteClassicTourLocaleMutation ||
          loadingDeleteStationMutation ||
          loadingUpdateStationOrder
        }
      />
      <MediaSelectorDialog
        dialogContext={mediaSelectDialogOpen}
        onClose={handleCloseMediaSelectDialog}
      />
      <DeleteDialog
        open={
          stationDialogContext.type === "delete" && stationDialogContext.open
        }
        title="Delete this Station?"
        description="This action is permanent!"
        buttonTitle="Delete"
        onClose={handleCloseStationDialog}
        mutation={handleRemoveOrderedStation}
      />
    </PageLayout>
  );
};
