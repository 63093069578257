import {
  Button,
  InputAdornment,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useStyles } from "./AboutUsPage.styles";
import { TabList, TabContext, TabPanel } from "@mui/lab";
import {
  DataHandlerComponent,
  FlagList,
  LoadingBackdrop,
  PageLayout,
  ReadyToPublish,
} from "../../../components";
import { useGlobalStyles } from "../../../utils/theme";
import {
  IDialogContext,
  IMediaSelectDialogContext,
  INPUT_SUBJECT_ABOUT_MAX_LENGTH,
  INPUT_SUBJECT_EXTRA_INFO_MAX_LENGTH,
  INPUT_SUBJECT_NAME_MAX_LENGTH,
  INPUT_SUBJECT_OPENING_HOURS_INFO_MAX_LENGTH,
  MAIN_ISO_LANGUAGE_CODE,
  MSG_UNSAVED_CHANGES,
  useForm,
} from "../../../utils";
import { initialInputData } from "./AboutUsPage.inputs";
import {
  AddOutlined as AddOutlinedIcon,
  ChevronRightOutlined as ChevronRightOutlinedIcon,
} from "@mui/icons-material";
import { ChevronLeftOutlined as ChevronLeftOutlinedIcon } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { Reference, StoreObject, useMutation, useQuery } from "@apollo/client";
import {
  CREATE_SUBJECT_LOCALE,
  DELETE_OPENING_HOUR,
  DELETE_SUBJECT_LOCALE,
  ICreateSubjectLocaleData,
  ICreateSubjectLocaleVars,
  IDeleteOpeningHourData,
  IDeleteOpeningHourVars,
  IDeleteSubjectLocaleData,
  IDeleteSubjectLocaleVars,
  IUpdateSubjectLocaleData,
  IUpdateSubjectLocaleVars,
  IUpdateSubjectOtherData,
  IUpdateSubjectOtherVars,
  IUpdateSubjectPublishedData,
  IUpdateSubjectPublishedVars,
  UPDATE_SUBJECT_LOCALE,
  UPDATE_SUBJECT_OTHER,
  UPDATE_SUBJECT_PUBLISHED,
} from "../../../apollo/mutations";
import {
  ISubjectDetailsData,
  ONE_SUBJECT_DETAILS,
} from "../../../apollo/queries";
import {
  MediaSelectButton,
  MediaSelectorDialog,
} from "../../../components/mediaSelectorContent/components";
import { OpeningHourItem } from "./components";
import { DeleteDialog } from "../../../components/deleteDialog/DeleteDialog";
import { UpsertOpeningHourDialog } from "./components/upsertOpeningHourDialog/UpsertOpeningHourDialog";

export const AboutUsPage: FC = () => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { classes: globalClasses } = useGlobalStyles();

  const TOTAL_TABS = "3";

  const [activeTab, setActiveTab] = useState("1");
  const [checkPassedPublishTest, setCheckPassedPublishTest] = useState(false);
  const [openDialog, setOpenDialog] = useState<IDialogContext>({
    open: false,
    id: undefined,
    type: undefined,
  });

  const handleCloseDialog = () => {
    setOpenDialog((prevState) => ({
      ...prevState,
      open: false,
      id: undefined,
    }));
  };

  const handleOpenCreateDialog = () => {
    setOpenDialog({
      type: "create",
      id: undefined,
      open: true,
    });
  };

  const [mediaSelectDialogOpen, setMediaSelectDialogOpen] =
    useState<IMediaSelectDialogContext>({
      open: false,
      type: undefined,
      max: undefined,
      onChange: undefined,
      selectedMedia: [],
    });

  const handleCloseMediaSelectDialog = () => {
    setMediaSelectDialogOpen((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const { loading, data, error, refetch } =
    useQuery<ISubjectDetailsData>(ONE_SUBJECT_DETAILS);

  const {
    inputFields,
    resetFields,
    validateForm,
    getFormValuesFromFetchedData,
    activeLocale,
    setActiveLocale,
    setCurrentLanguageFlags,
    inputFieldNames,
    didValuesChange,
    currentLanguageFlags,
    handleDataToVar,
  } = useForm<keyof typeof initialInputData>(initialInputData);

  const [
    deleteSubjectLocaleMutation,
    { loading: loadingDeleteSubjectLocaleMutation },
  ] = useMutation<IDeleteSubjectLocaleData, IDeleteSubjectLocaleVars>(
    DELETE_SUBJECT_LOCALE,
    {
      onCompleted: (data) => {
        enqueueSnackbar("Locale flag deleted", {
          variant: "success",
        });
        resetFields(["name", "about", "extraInfo", "openingHoursInfo"]);
        setActiveLocale(MAIN_ISO_LANGUAGE_CODE);
        const newCurrentFlags = currentLanguageFlags.filter(
          (x) => x.id !== data.deleteSubjectLocale.localeId
        );
        setCurrentLanguageFlags(newCurrentFlags);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      update(cache, { data: dataCreate }) {
        if (data?.oneSubjectDetails) {
          cache.modify({
            id: cache.identify(
              data.oneSubjectDetails as unknown as StoreObject
            ),
            fields: {
              allLocales(existingData: Array<Reference>, { readField }) {
                if (data) {
                  if (existingData && dataCreate) {
                    return existingData.filter(
                      (taskRef) =>
                        dataCreate.deleteSubjectLocale.id !==
                        readField("id", taskRef)
                    );
                  }
                }
              },
            },
          });
        }
      },
    }
  );

  const [
    updateSubjectLocaleMutation,
    { loading: loadingUpdateSubjectLocaleMutation },
  ] = useMutation<IUpdateSubjectLocaleData, IUpdateSubjectLocaleVars>(
    UPDATE_SUBJECT_LOCALE,
    {
      onCompleted: (data) => {
        enqueueSnackbar("About us updated", {
          variant: "success",
        });
        resetFields(
          ["name", "about", "extraInfo", "openingHoursInfo"],
          true,
          false
        );
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
    }
  );

  const [
    updateSubjectOtherMutation,
    { loading: loadingUpdateSubjectOtherMutation },
  ] = useMutation<IUpdateSubjectOtherData, IUpdateSubjectOtherVars>(
    UPDATE_SUBJECT_OTHER,
    {
      onCompleted: (data) => {
        enqueueSnackbar("Other info updated", {
          variant: "success",
        });
        resetFields(["phone", "email", "header"], true);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
    }
  );

  const [
    deleteOpeningHourMutation,
    { loading: loadingDeleteOpeningHourMutation },
  ] = useMutation<IDeleteOpeningHourData, IDeleteOpeningHourVars>(
    DELETE_OPENING_HOUR,
    {
      onCompleted: (res) => {
        enqueueSnackbar("Opening hour deleted!", { variant: "success" });
        handleCloseDialog();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      update(cache, { data: dataDelete }) {
        // console.log(cache);
        if (data?.oneSubjectDetails) {
          cache.modify({
            id: cache.identify(
              data.oneSubjectDetails as unknown as StoreObject
            ),
            fields: {
              allOpeningHours(existingData: Array<Reference>, { readField }) {
                if (data) {
                  // console.log(existingData);
                  if (existingData && dataDelete) {
                    return existingData.filter(
                      (taskRef) =>
                        dataDelete.deleteOpeningHour.id !==
                        readField("id", taskRef)
                    );
                  }
                }
              },
            },
          });
        }
      },
    }
  );

  const handleDeleteOpeningHour = () => {
    if (openDialog.id && openDialog.type === "delete") {
      deleteOpeningHourMutation({ variables: { id: +openDialog.id } });
    } else {
      enqueueSnackbar("Failed to call delete mutation");
    }
  };

  const [
    createSubjectLocaleMutation,
    { loading: loadingCreateSubjectLocaleMutation },
  ] = useMutation<ICreateSubjectLocaleData, ICreateSubjectLocaleVars>(
    CREATE_SUBJECT_LOCALE,
    {
      onCompleted: (data) => {
        enqueueSnackbar("Locale created", {
          variant: "success",
        });
        resetFields(["name", "about", "extraInfo", "openingHoursInfo"], true);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      update(cache, { data: dataCreate }) {
        // console.log(cache);
        if (data?.oneSubjectDetails) {
          cache.modify({
            id: cache.identify(
              data.oneSubjectDetails as unknown as StoreObject
            ),
            fields: {
              allLocales(existingData: Array<Reference>, { readField }) {
                if (data) {
                  if (existingData && dataCreate) {
                    return [
                      ...existingData,
                      {
                        // @ts-ignore
                        __ref: `${dataCreate.createSubjectLocale.__typename}:${dataCreate.createSubjectLocale.id}`,
                      },
                    ];
                  }
                }
              },
            },
          });
        }
      },
    }
  );

  const handleTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setActiveTab(newTab);
  };

  const handleNextTab = () => {
    const nextTab = (parseInt(activeTab) + 1).toString();
    setActiveTab(nextTab);
  };

  const handlePrevTab = () => {
    const prevTab = (parseInt(activeTab) - 1).toString();
    setActiveTab(prevTab);
  };

  useEffect(() => {
    // console.log("useEffect");
    if (!data?.oneSubjectDetails) {
      return;
    }

    getFormValuesFromFetchedData(
      data.oneSubjectDetails,
      [
        {
          fromDataProperty: "allLocales.name",
          toFormProperty: "name",
        },
        {
          fromDataProperty: "allLocales.about",
          toFormProperty: "about",
        },
        {
          fromDataProperty: "allLocales.extraInfo",
          toFormProperty: "extraInfo",
        },
        {
          fromDataProperty: "allLocales.openingHoursInfo",
          toFormProperty: "openingHoursInfo",
        },
        {
          fromDataProperty: "email",
          toFormProperty: "email",
        },
        {
          fromDataProperty: "phone",
          toFormProperty: "phone",
        },
        {
          fromDataProperty: "header.id",
          toFormProperty: "header",
        },
      ],
      true,
      true
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleUpdateLocale = () => {
    const getUpdateLocaleId = data?.oneSubjectDetails.allLocales?.find(
      (x) => x.languageFlag?.isoLanguageCode === activeLocale
    )?.id;

    if (!validateForm(["name", "about", "extraInfo", "name"])) {
      return;
    }

    if (getUpdateLocaleId) {
      updateSubjectLocaleMutation({
        variables: {
          id: +getUpdateLocaleId,
          data: {
            name: handleDataToVar("name", "string"),
            about: handleDataToVar("about", "string"),
            extraInfo: handleDataToVar("extraInfo", "string"),
            openingHoursInfo: handleDataToVar("openingHoursInfo", "string"),
          },
        },
      });
    } else {
      createSubjectLocaleMutation({
        variables: {
          data: {
            name: handleDataToVar("name", "string", false),
            about: handleDataToVar("about", "string", false),
            extraInfo: handleDataToVar("extraInfo", "string", false),
            openingHoursInfo: handleDataToVar(
              "openingHoursInfo",
              "string",
              false
            ),
            isoLanguageCode: activeLocale,
          },
        },
      });
    }
  };

  const handleUpdateOther = () => {
    if (
      !validateForm(
        ["header", "phone", "email"],
        Boolean(data?.oneSubjectDetails.published)
      )
    ) {
      return;
    }
    updateSubjectOtherMutation({
      variables: {
        data: {
          email: handleDataToVar("email", "string"),
          phone: handleDataToVar("phone", "string"),
          headerId: handleDataToVar("header", "number"),
        },
      },
    });
  };

  const [
    updateSubjectPublishedMutation,
    { loading: loadingUpdateTourPublishedMutation },
  ] = useMutation<IUpdateSubjectPublishedData, IUpdateSubjectPublishedVars>(
    UPDATE_SUBJECT_PUBLISHED,
    {
      onCompleted: (data) => {
        if (!data.updateSubjectPublished) {
          enqueueSnackbar(`Subject is ready to be published!`, {
            variant: "info",
          });
          setCheckPassedPublishTest(true);
        } else {
          enqueueSnackbar(
            `Subject is now ${
              data.updateSubjectPublished.published
                ? "published"
                : "unpublished"
            }!`,
            {
              variant: "success",
            }
          );
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
    }
  );

  const handleUpdateSubjectPublished = () => {
    if (data) {
      if (
        didValuesChange(inputFieldNames, true) &&
        !data.oneSubjectDetails.published
      ) {
        enqueueSnackbar(MSG_UNSAVED_CHANGES, { variant: "warning" });
        return;
      }
      if (
        validateForm(inputFieldNames, !data.oneSubjectDetails.published, true)
      ) {
        updateSubjectPublishedMutation({
          variables: {
            publish: data?.oneSubjectDetails.published ? false : true,
            checkOnly:
              !data?.oneSubjectDetails.published && !checkPassedPublishTest,
          },
        });
      }
    }
  };

  const handleDeleteSubjectLocale = () => {
    const localeId = data?.oneSubjectDetails?.allLocales?.find(
      (x) => x.languageFlag.isoLanguageCode === activeLocale
    )?.id;
    if (localeId) {
      deleteSubjectLocaleMutation({
        variables: {
          id: +localeId,
        },
      });
    } else {
      const newCurrentFlags = currentLanguageFlags.filter(
        (x) => x.isoLanguageCode !== activeLocale
      );
      resetFields(["name", "about", "openingHoursInfo", "extraInfo"]);
      setCurrentLanguageFlags(newCurrentFlags);
      setActiveLocale(MAIN_ISO_LANGUAGE_CODE);
      enqueueSnackbar("Locale has been removed!", { variant: "success" });
    }
  };

  return (
    <PageLayout displayFlex>
      <TabContext value={activeTab}>
        <TabList onChange={handleTabChange} aria-label="lab API tabs example">
          <Tab label="General" value="1" />
          <Tab label="Other" value="2" />
          <Tab label="Opening Hours" value="3" />
        </TabList>
        <Paper className={globalClasses.paperRoot}>
          <div className={globalClasses.paperTitle}>
            <div className={globalClasses.justifySpaceBetween}>
              <Typography component="h1" variant="h5">
                Edit Subject
              </Typography>
              {data?.oneSubjectDetails ? (
                <ReadyToPublish
                  published={data.oneSubjectDetails.published}
                  checkPassed={checkPassedPublishTest}
                  handlePublish={handleUpdateSubjectPublished}
                  publishedThingText="Subject"
                />
              ) : null}
            </div>
          </div>

          <DataHandlerComponent
            hasData={Boolean(data?.oneSubjectDetails)}
            error={Boolean(error)}
            loading={loading}
          >
            <div className={globalClasses.paperContainer}>
              <TabPanel value="1" className={classes.tabPanel}>
                <FlagList
                  deleteLocaleFlagMutation={handleDeleteSubjectLocale}
                  canSelectFlags
                  activeLocale={activeLocale}
                  setActiveLocale={setActiveLocale}
                  currentLanguageFlags={currentLanguageFlags}
                  setCurrentLanguageFlags={setCurrentLanguageFlags}
                  type="Subject"
                  tooltip="New translations for Interactive tours, Audio tours, Stations and Checkpoints will only be visible to users if you have already added them here."
                />
                <Typography color="textSecondary">
                  Add information about your subject.
                </Typography>
                <TextField
                  margin="normal"
                  {...inputFields.name.inputProps}
                  autoFocus
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_SUBJECT_NAME_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.name.inputProps.value.length}/${INPUT_SUBJECT_NAME_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="normal"
                  {...inputFields.about.inputProps}
                  autoFocus
                  multiline
                  minRows={6}
                  maxRows={10}
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_SUBJECT_ABOUT_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.about.inputProps.value.length}/${INPUT_SUBJECT_ABOUT_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  margin="normal"
                  {...inputFields.extraInfo.inputProps}
                  multiline
                  minRows={6}
                  maxRows={10}
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_SUBJECT_EXTRA_INFO_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.extraInfo.inputProps.value.length}/${INPUT_SUBJECT_EXTRA_INFO_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="normal"
                  {...inputFields.openingHoursInfo.inputProps}
                  multiline
                  minRows={6}
                  maxRows={10}
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_SUBJECT_OPENING_HOURS_INFO_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.openingHoursInfo.inputProps.value.length}/${INPUT_SUBJECT_OPENING_HOURS_INFO_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </TabPanel>
              <TabPanel className={classes.tabPanel} value="2">
                <Typography color="textSecondary">
                  Add additional information about your subject.
                </Typography>

                <TextField
                  margin="normal"
                  {...inputFields.email.inputProps}
                  fullWidth
                />
                <TextField
                  fullWidth
                  margin="normal"
                  {...inputFields.phone.inputProps}
                />
                <MediaSelectButton
                  setMediaSelectDialogOpen={setMediaSelectDialogOpen}
                  max={1}
                  pickType="image"
                  {...inputFields.header.inputProps}
                />
              </TabPanel>
              <TabPanel className={classes.tabPanel} value="3">
                <div className={globalClasses.justifySpaceBetween}>
                  <Typography component="p" variant="h6">
                    Opening Hours
                  </Typography>
                  <Button
                    onClick={handleOpenCreateDialog}
                    variant="outlined"
                    startIcon={<AddOutlinedIcon />}
                  >
                    Add new
                  </Button>
                </div>
                <TableContainer className={globalClasses.tableContainer}>
                  <Table
                    className={globalClasses.table}
                    // sx={{ minWidth: 650 }}
                    aria-label="List of opening hours"
                  >
                    <TableHead>
                      <TableRow
                      // className={classes.tableRow}
                      >
                        <TableCell width={32} align="left">
                          #
                        </TableCell>
                        <TableCell align="left">Day</TableCell>
                        <TableCell width={160} align="left">
                          Start hour
                        </TableCell>
                        <TableCell width={160} align="left">
                          End hour
                        </TableCell>
                        <TableCell width={180} align="right">
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.oneSubjectDetails.allOpeningHours.length
                        ? data?.oneSubjectDetails.allOpeningHours.map(
                            (item, i) => {
                              const handleOpenDeleteDialog = () => {
                                setOpenDialog({
                                  open: true,
                                  id: item.id,
                                  type: "delete",
                                });
                              };
                              const handleOpenEditDialog = () => {
                                setOpenDialog({
                                  open: true,
                                  id: item.id,
                                  type: "update",
                                });
                              };
                              return (
                                <OpeningHourItem
                                  key={item.id}
                                  index={i + 1}
                                  data={item}
                                  handleDelete={handleOpenDeleteDialog}
                                  handleUpdate={handleOpenEditDialog}
                                />
                              );
                            }
                          )
                        : null}
                    </TableBody>
                  </Table>
                  <Typography color="textSecondary">
                    Your organization working hours. For double working hours,
                    add two working hours on the same day.
                  </Typography>
                </TableContainer>
              </TabPanel>
            </div>
            <div className={globalClasses.paperButtons}>
              <div className={globalClasses.paperButtonsLeft}>
                {activeTab !== "1" ? (
                  <Button
                    className={classes.buttonPrevious}
                    onClick={handlePrevTab}
                    variant="outlined"
                    color="inherit"
                    startIcon={<ChevronLeftOutlinedIcon />}
                  >
                    Previous
                  </Button>
                ) : null}
              </div>
              <div className={globalClasses.paperButtonsRight}>
                {activeTab !== TOTAL_TABS ? (
                  <Button
                    onClick={handleNextTab}
                    variant="outlined"
                    endIcon={<ChevronRightOutlinedIcon />}
                  >
                    Next
                  </Button>
                ) : null}
                {activeTab === "1" ? (
                  <Button
                    onClick={handleUpdateLocale}
                    variant="contained"
                    disabled={
                      !didValuesChange([
                        "name",
                        "about",
                        "extraInfo",
                        "openingHoursInfo",
                      ]) &&
                      !!data?.oneSubjectDetails.allLocales?.find(
                        (x) => x.languageFlag?.isoLanguageCode === activeLocale
                      )?.id
                    }
                  >
                    {!data?.oneSubjectDetails.allLocales?.find(
                      (x) => x.languageFlag?.isoLanguageCode === activeLocale
                    )?.id
                      ? "create "
                      : "update "}
                    | {activeLocale} |
                  </Button>
                ) : null}
                {activeTab === "2" ? (
                  <Button
                    onClick={handleUpdateOther}
                    variant="contained"
                    disabled={!didValuesChange(["email", "header", "phone"])}
                  >
                    Update
                  </Button>
                ) : null}
              </div>
            </div>
          </DataHandlerComponent>
        </Paper>
      </TabContext>
      <LoadingBackdrop
        loading={
          loadingUpdateSubjectOtherMutation ||
          loadingUpdateSubjectLocaleMutation ||
          loadingCreateSubjectLocaleMutation ||
          loadingUpdateTourPublishedMutation ||
          loadingDeleteOpeningHourMutation ||
          loadingDeleteSubjectLocaleMutation
        }
      />
      <MediaSelectorDialog
        dialogContext={mediaSelectDialogOpen}
        onClose={handleCloseMediaSelectDialog}
      />
      <DeleteDialog
        open={openDialog.type === "delete" && openDialog.open}
        title="Delete this opening hour?"
        description="This action is permanent!"
        onClose={handleCloseDialog}
        mutation={handleDeleteOpeningHour}
      />
      <UpsertOpeningHourDialog
        open={
          (openDialog.type === "create" || openDialog.type === "update") &&
          openDialog.open
        }
        type={openDialog.type}
        id={openDialog.id}
        onClose={handleCloseDialog}
        refetch={refetch}
      />
    </PageLayout>
  );
};
